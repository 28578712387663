import * as Sentry from '@sentry/vue'
import Vue from 'vue'

const sentryEnvironment = process.env.VUE_APP_ENV || 'production'
console.info('[SENTRY ENV]', sentryEnvironment)
if (sentryEnvironment && sentryEnvironment !== 'local') {
  let release
  try {
    const info = require('../../public/health.json')
    release = info.VERSION
  } catch (err) {
    console.error(err)
    release = 'local'
  }

  Sentry.init({
    Vue,
    dsn: 'https://c1ea04e97ce04a3dae333ba3bd40193e@o239847.ingest.sentry.io/5694555',
    integrations: [
      Sentry.replayIntegration({
        blockAllMedia: false,
        unmask: ['.s-unmask']
      }),
      Sentry.replayCanvasIntegration()
    ],
    release,
    attachProps: true,
    logErrors: true,
    environment: sentryEnvironment,
    // Session Replay
    replaysSessionSampleRate: sentryEnvironment === 'production' ? 1.0 : 0.5,
    replaysOnErrorSampleRate: 1.0
  })
}
