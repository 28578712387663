<template lang="pug">
.authentication
  .background(:style="background")
  fp-loader(v-if='$store.getters.ORGANIZATION_LOADING')
  .content(
    v-else
    :class='{ "dataplatform": isDataplatform }'
  )
    .beta-banner(v-if="isDataplatform")
      span {{ $t('authentication.beta_header') }}
    logout(v-if="logout" :logout-done='logoutDone')
    organization-login(v-else-if="$store.getters.NEED_ORGANIZATION_LOGIN && $store.getters.SESSION")
    div(v-else-if="!$store.getters.SESSION")
      login-all-in-one(v-if="allInOne")
      login-selector(
        v-else-if="!mode"
        @select="(value)=>mode=value"
      )
      login(
        v-else-if="mode"
        @close="mode=null"
        :mode="mode"
      )
    mfa(v-else-if="needMfa" @logout-done='logoutDone = true')
    password-renew(v-else-if="needRenew")
</template>

<script>
import Login from './Login'
import OrganizationLogin from './OrganizationLogin'
import Logout from './Logout.vue'
import LoginSelector from './LoginSelector.vue'
import LoginAllInOne from './LoginAllInOne.vue'
import Mfa from './MFA'
import Config from '@/shared/Config'

import backgroundTexture from '@/shared/assets/img/login_background.svg'
const PasswordRenew = () => import('./PasswordRenew.vue')

export default {
  components: { LoginAllInOne, LoginSelector, Login, PasswordRenew, Logout, Mfa, OrganizationLogin },
  props: {
    organizationId: { type: String, default: () => null }
  },
  data () {
    return {
      mode: null,
      logoutDone: false,
      config: {}
    }
  },
  computed: {
    isDataplatform () {
      return this.config?.DATA_PLATFORM_ACCESS
    },
    allInOne () {
      return this.authModes?.length > 1
    },
    authModes () {
      return this.$store.getters.PREFERENCES?.auth_mode.filter(authMode => {
        return authMode.visible && !authMode.hidden
      })
    },
    needRenew () {
      return this.$store.getters.SESSION?.password_renew
    },
    needMfa () {
      if (!this.$store.getters.SESSION) return false
      return this.$store.getters.SESSION.acr === 'aal1'
    },
    logout () {
      return typeof (this.$route.query.logout) !== 'undefined'
    },
    background () {
      return {
        background: `url(${backgroundTexture}) no-repeat, linear-gradient(180deg, #035599 0%, #00B8E0 54.17%, #035599 100%) no-repeat, radial-gradient(59.98% 30.76% at 29.67% 50%, rgba(255, 255, 255, 0.00) 0%, rgba(217, 217, 217, 0.22) 100%)`,
        'background-size': 'cover'
      }
    }
  },
  async mounted () {
    this.config = await Config()
  }
}
</script>

<style lang="less">
  .authentication {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    > .background {
      position: fixed;
      width: 100vw;
      height: 100vh;
      bottom: 0;
      z-index: 0;
    }
    .content {
      z-index: 1;
      border-radius: 10px;
      background: #FFF;
      box-shadow: 0px 2px 4px 0px rgba(52, 65, 78, 0.05);
      width: 558px;
      padding: 24px;
      margin: 20px 0;
      &.dataplatform {
        padding: 0 24px 24px 24px;
      }
      > .beta-banner {
        background-color: #00CCF9;
        color: #FFF;
        padding: 8px;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 16px;
        margin-left: -24px;
        margin-right: -24px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      h1 {
        color: @text-color;
        font-size: 38px;
        font-weight: 600;
        margin-top: 10px;
      }
    }
    .logo {
      width: 150px;
    }
  }
</style>
